import { useEffect, useState } from 'react';

import { eventGtag } from '#app/hooks/eventGtag';
import { Typography } from '#atoms/index';
import { useHeaderSliders } from '#layout/index';
import { useUserContext } from '#hooks/index';
import navData from '#helpers/navbar-data';
import { ArrowBottomIco, ArrowTopIco } from '#layout/Header/Helpers/icons';

import dropdownType from './DropdownType/dropdown-type';

const arrowType = isDropdownOpen => {
  switch (isDropdownOpen) {
    case true:
      return <ArrowTopIco />;
    case false:
      return <ArrowBottomIco />;
    default:
      return '';
  }
};

const Dropdown = ({
 slider, name, arrow, option, type, eventNameAnalytics 
}) => {
  const { data: userData } = useUserContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [subData, setSubData] = useState(null);
  const { sliders } = useHeaderSliders();

  const handleOpenDropdown = () => setIsDropdownOpen(true);
  const handleCloseDropdown = () => setIsDropdownOpen(false);

  const [imagePopup, setImagePopup] = useState();

  useEffect(async () => {
    setImagePopup([
      './popup/desktop.webp',
      'https://web.crackthecode.la/conoce-a-crack-the-code'
    ]);
  }, []);

  return (
    <div
      className={option == 'login' ? 'trigger-login' : 'trigger'}
      onMouseEnter={() => {
        handleOpenDropdown();
        setSubData(null);
        if (eventNameAnalytics) {
          eventGtag(eventNameAnalytics, userData);
        }
      }}
      onMouseLeave={() => handleCloseDropdown()}
    >
      <Typography variant="regular regular-6 font-normal  ntbau" tag="span">
        {name}
      </Typography>

      {arrow && arrowType(isDropdownOpen)}

      {/* Dropdown */}
      {isDropdownOpen && (
        <section
          className={option == 'login' ? 'dropdown-Wrapper-login' : 'dropdown-Wrapper'}
        >
          {dropdownType({
            sliders,
            type,
            navData,
            option,
            subData,
            setSubData,
            slider,
            imagePopup,
            userData
          })}
        </section>
      )}
    </div>
  );
};

export default Dropdown;
