import { eventGtag } from '#app/hooks/eventGtag';
import { Link, Typography } from '#atoms/index';
import { ArrowRightDropdownType } from '#layout/Header/Helpers/icons';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import Skeleton from 'react-loading-skeleton';
// import Slider from '../Slider/slider';

const dropdownType = ({
  sliders,
  type,
  navData,
  option,
  subData,
  setSubData,
  imagePopup,
  userData
}) => {
  // const handleClick = () => window.open(imagePopup[1], '_blank');

  const handleClickEvents = eventCase => {
    if (eventCase === '/cursos') {
      eventGtag('cursos_menu');
    } else if (eventCase === '/rutas') {
      eventGtag('rutas_menu');
    } else if (eventCase === '/cursos/clases-privadas') {
      eventGtag('clases_privadas_menu');
    } else if (eventCase === '¿Qué es Crack The Code?') {
      eventGtag('ctc_menu');
    } else if (eventCase === 'Blog') {
      eventGtag('blog_menu');
    } else if (eventCase === 'Trabaja con nosotros') {
      eventGtag('work_with_us_menu');
    }
  };

  switch (type) {
    case 'simple':
      return (
        <ul className="dropdown dropdown--simple">
          {option &&
            navData[option].map((item, idx) => {
              return (
                <li
                  key={idx}
                  className="dropdown__item"
                  onClick={() => handleClickEvents(item.title)}
                >
                  {item.redirect ? (
                    <Link href={item.redirect} passHref={true} target="_blank">
                      <Typography variant="regular regular-6  ntbau" tag="span">
                        {item.title}
                      </Typography>
                    </Link>
                  ) : (
                    <Link href={item.url}>
                      <Typography variant="regular regular-6  ntbau" tag="span">
                        {item.title}
                      </Typography>
                    </Link>
                  )}
                </li>
              );
            })}
        </ul>
      );
    case 'complex':
      return (
        <div className="dropdown dropdown--complex">
          {/* Left */}
          <ul className="dropdown--complex_left">
            {option &&
              navData[option].map((item, idx) => {
                return item.url ? (
                  <li
                    key={idx}
                    className="dropdown__item dropdown__item--complex"
                    onMouseEnter={() => setSubData(null)}
                    onClick={() => handleClickEvents(item.url)}
                  >
                    <Link href={item.url}>
                      <Typography variant="regular regular-6  ntbau" tag="span">
                        {item.title}
                      </Typography>
                    </Link>
                  </li>
                ) : (
                  <li
                    className="dropdown__item dropdown__item--complex"
                    onMouseEnter={() => {
                      setSubData(item.data);
                      if (item.title === 'Programas especiales') {
                        eventGtag('programas_especiales_menu');
                      }
                    }}
                    key={idx}
                  >
                    <Typography variant="regular regular-6  ntbau" tag="span">
                      {item.title}
                    </Typography>

                    <ArrowRightDropdownType />
                  </li>
                );
              })}
          </ul>

          {/* Center */}
          <div className="dropdown--complex_center">
            {subData &&
              subData.map((item, idx) => {
                return item.url ? (
                  <li
                    key={idx}
                    onClick={() => handleClickEvents(item.url)}
                    className="dropdown__item dropdown__item--complex"
                  >
                    <Link href={item.url} key={idx}>
                      <Typography variant="regular regular-6  ntbau" tag="p">
                        {item.title}
                      </Typography>
                    </Link>
                  </li>
                ) : (
                  <li className="dropdown__item dropdown__item--complex" key={idx}>
                    <Link href={item.redirect} passHref={true} target="_blank">
                      <Typography variant="regular regular-6  ntbau" tag="p">
                        {item.title}
                      </Typography>
                    </Link>
                  </li>
                );
              })}
          </div>

          {/* Right */}
          {/* <div className="dropdown--complex_right" onClick={() => handleClick()}>
            <LazyLoadImage
              width="100%"
              height="100%"
              effect="blur"
              alt="popup-background"
              src={imagePopup[0]}
              placeholder={<Skeleton width="100%" height="100%" />}
            />
          </div> */}
        </div>
      );
  }
};

export default dropdownType;
