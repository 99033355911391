export const CloseIco = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.0013 0.444013L0.445665 1.99965L7.44604 9.00002L0.445686 16.0004L2.00132 17.556L9.00167 10.5557L16.002 17.556L17.5576 16.0004L10.5573 9.00002L17.5577 1.99966L16.002 0.444025L9.00167 7.44439L2.0013 0.444013Z"
        fill="black"
      />
    </svg>
  );
};

export const ArrowRightSlider = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.27947 4.33615H9.27808L6.82994 1.4394C6.71546 1.30372 6.66039 1.1288 6.67683 0.953119C6.69327 0.777437 6.77988 0.615384 6.91761 0.502611C7.05534 0.389838 7.2329 0.335582 7.41124 0.35178C7.58957 0.367978 7.75407 0.453302 7.86854 0.588982L11.2406 4.57534C11.2633 4.60704 11.2836 4.64035 11.3013 4.675C11.3013 4.70821 11.3013 4.72815 11.3485 4.76137C11.3791 4.83754 11.3951 4.91863 11.3958 5.00055C11.3951 5.08246 11.3791 5.16355 11.3485 5.23973C11.3485 5.27295 11.3485 5.29288 11.3013 5.3261C11.2836 5.36074 11.2633 5.39405 11.2406 5.42576L7.86854 9.41211C7.80513 9.48711 7.72573 9.54742 7.63597 9.58876C7.54622 9.6301 7.44832 9.65145 7.34924 9.65129C7.19166 9.6516 7.03895 9.59753 6.91761 9.49848C6.84932 9.44271 6.79287 9.37421 6.75149 9.29691C6.71012 9.21961 6.68463 9.13503 6.67648 9.04801C6.66834 8.96099 6.6777 8.87324 6.70403 8.78979C6.73037 8.70634 6.77315 8.62883 6.82994 8.56169L9.27808 5.66494H1.27947C1.10061 5.66494 0.929064 5.59494 0.802587 5.47034C0.676108 5.34575 0.605055 5.17675 0.605055 5.00055C0.605055 4.82434 0.676108 4.65535 0.802587 4.53075C0.929064 4.40615 1.10061 4.33615 1.27947 4.33615Z"
        fill="white"
      />
    </svg>
  );
};

export const ArrowRightDropdownType = () => {
  return (
    <svg
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33345 0L0.158447 1.175L3.97511 5L0.158447 8.825L1.33345 10L6.33345 5L1.33345 0Z"
        fill="black"
      />
    </svg>
  );
};

export const ArrowTopIco = () => {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3207 7.40997L6.73071 2.82997L2.14071 7.40997L0.730713 5.99997L6.73071 -2.67029e-05L12.7307 5.99997L11.3207 7.40997Z"
        fill="black"
      />
    </svg>
  );
};

export const ArrowBottomIco = () => {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3207 0.590027L6.73071 5.17003L2.14071 0.590027L0.730713 2.00003L6.73071 8.00003L12.7307 2.00003L11.3207 0.590027Z"
        fill="black"
      />
    </svg>
  );
};

export const ArrowLeftSidebar = () => {
  return (
    <svg
      width="13"
      height="22"
      viewBox="0 0 13 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.78836 1.09231C10.3822 0.491198 11.3528 0.491198 11.9467 1.09231C12.5305 1.68322 12.5305 2.63373 11.9467 3.22463L4.26496 11L11.9467 18.7754C12.5305 19.3663 12.5305 20.3168 11.9467 20.9077C11.3528 21.5088 10.3822 21.5088 9.78836 20.9077L0 11L9.78836 1.09231Z"
        fill="black"
      />
    </svg>
  );
};

export const ArrowRightSidebar = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 0L0.589996 1.41L5.17 6L0.589996 10.59L2 12L8 6L2 0Z" fill="black" />
    </svg>
  );
};
